<template>
  <div>
    <ml-container>
      <ml-grid>
        <div class="tw-mt-12 tw-col-start-2 tw-col-span-6">
          <page-title class="tw-text-black tw-text-center">{{ $t('faq')}} </page-title>

            <div class="tw-border-b tw-border-gray-n0 tw-flex tw-justify-between tw-items-end">
              <nav class="tw--mb-px tw-flex tw-space-x-8 tw-leading-relaxed" aria-label="Tabs">
                <a @click.prevent="faqTab = 'couples'" href="#" :class="[faqTab === 'couples' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']">
                  <span>For Couples </span>
                </a>
                <a @click.prevent="faqTab = 'vendors'" :class="[faqTab === 'vendors' ? 'tw-border-black tw-text-black' : 'tw-border-transparent tw-text-gray-n2 hover:tw-text-gray-n3 hover:tw-border-gray-n3', 'tw-no-underline tw-group tw-inline-flex tw-items-center tw-py-3 tw-px-1 tw-border-b-2 tw-font-medium tw-text-sm']" href="#">
                  <span>For Vendors </span>
                </a>
              </nav>
            </div>

            <div class="tw-flex tw-font-semibold tw-mb-3 tw-pb-3 tw-border-bottom tw-border-gray-n0 tw-leading-relaxed">
              <h5></h5>
              <h5></h5>
            </div>

          <dl v-show="faqTab === 'couples'" class="tw-mt-6 tw-space-y-5">
            <div v-for="(faq, index) in userfaqs" v-bind:key="index" class="tw-border tw-py-4 tw-px-6 tw-border-gray-n1 tw-rounded-lg">
              <dt class="tw-text-sm">
                <!-- Expand/collapse question button -->
                <button @click="faq.show = !faq.show" type="button" class="tw-text-left tw-w-full tw-flex tw-justify-between tw-items-start" aria-controls="faq-0" aria-expanded="false">
                  <span class="tw-font-medium tw-text-gray-900 tw-text-black tw-flex tw-items-center tw-font-semibold">
                    <span class="tw-font-serif tw-text-2xl tw-mr-6 tw-text-gray-n2 tw-font-normal">{{ String(index + 1).padStart(2, '0') }}</span>
                    {{ faq.question }}
                  </span>
                  <span class="tw-ml-6 tw-h-7 tw-flex tw-items-center">
                    <!--
                      Expand/collapse icon, toggle classes based on question open state.

                      Heroicon name: outline/chevron-down

                      Open: "-rotate-180", Closed: "rotate-0"
                    -->
                    <svg :class="[faq.show ? 'tw--rotate-180' : 'tw-rotate-0', 'tw-h-3 tw-w-3 tw-transform']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 9" stroke="currentColor" aria-hidden="true">
                      <path d="M1 1.5L7 7.5L13 1.5" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                </button>
              </dt>
              <dd class="tw-mt-3 tw-pr-12" id="faq-0" v-show="faq.show">
                <div v-for="(item, index) in faq.answers" v-bind:key="index">
                   <p class="tw-text-sm tw-leading-relaxed tw-mb-0">
                    <span v-html="item.line"/>
                    </p>
                </div>                
              </dd>
            </div>
          </dl>

          <dl v-show="faqTab === 'vendors'" class="tw-mt-6 tw-space-y-5">
            <div v-for="(faq, index) in vendorfaqs" v-bind:key="index" class="tw-border tw-py-4 tw-px-6 tw-border-gray-n1 tw-rounded-lg">
              <dt class="tw-text-sm">
                <!-- Expand/collapse question button -->
                <button @click="faq.show = !faq.show" type="button" class="tw-text-left tw-w-full tw-flex tw-justify-between tw-items-start" aria-controls="faq-0" aria-expanded="false">
                  <span class="tw-font-medium tw-text-gray-900 tw-text-black tw-flex tw-items-center tw-font-semibold">
                    <span class="tw-font-serif tw-text-2xl tw-mr-6 tw-text-gray-n2 tw-font-normal">{{ String(index + 1).padStart(2, '0') }}</span>
                    {{ faq.question }}
                  </span>
                  <span class="tw-ml-6 tw-h-7 tw-flex tw-items-center">
                    <!--
                      Expand/collapse icon, toggle classes based on question open state.

                      Heroicon name: outline/chevron-down

                      Open: "-rotate-180", Closed: "rotate-0"
                    -->
                    <svg :class="[faq.show ? 'tw--rotate-180' : 'tw-rotate-0', 'tw-h-3 tw-w-3 tw-transform']" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 9" stroke="currentColor" aria-hidden="true">
                      <path d="M1 1.5L7 7.5L13 1.5" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                </button>
              </dt>
              <dd class="tw-mt-3 tw-pr-12" id="faq-0" v-show="faq.show">
                <div v-for="(item, index) in faq.answers" v-bind:key="index">
                  <p class="tw-text-sm tw-leading-relaxed tw-mb-1">
                    <span v-html="item.line"/>
                  </p>
                </div>
                <div class="tw-mt-3">
                    <video v-if="faq.video" controls :id="index" 
                        @playing="play"
                        @pause="pause(faq,$event)"
                        @canplay="updatePaused"
                    
                      >
                      <source :src="faq.video"
                              type="video/mp4">
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                    <a v-if="faq.url" :href="faq.url" target="_blank">{{faq.question}}</a>
                  </div>
                    
              </dd>
            </div>
          </dl>

        </div>
      </ml-grid>
    </ml-container>
  </div>
</template>


<script>
import i18n from '@/i18n'
import MlContainer from "@/components/layout/MlContainer";
import PageTitle from "@/components/layout/titles/PageTitle";
import MlGrid from "@/components/layout/MlGrid";

export default {
  components: {
    MlGrid,
    PageTitle,
    MlContainer,
  },
  layout: 'basic',
  metaInfo() {
    return {
      title: i18n.t('faq-title'),
      meta: [
        {
          name: 'description',
          content: i18n.t('description.faq')
        }
      ]
    }
  },
  data() {
    return {
      faqTab: 'couples',
      playing: false,
      videoElements: [],
      userfaqs: [
        {
          question: 'How can I book a Vendor on M&L?',
          answers: [
            {
              line: 'If you are ready to book a wedding professional on Mango & Lola, you will have the opportunity to choose either “Auto-Booking” or “Booking Request”.'
            },
          ],
          show: false,
          url: null
        },
        {
          question: 'What is Auto-Booking?',
          answers: [
            {
              line: 'With “Auto-Booking”, couples can book a Vendor instantly without any vendor approval as long as the wedding is in the Vendor’s “Area of Coverage”. You will be able to see the booking details immediately in your account under “My Vendors”.'
            },
          ],
          show: false,
          url: null
        },
        {
          question: 'What is a Booking Request?',
          answers: [
            {
              line: 'With the “Booking Request” option, a Vendor has 48 hours to accept your request. Once a Vendor approves your booking request, the booking details will appear in your account under “My Vendors”.'
            },
          ],
          show: false,
          url: null
        },
        {
          question: 'What is a Custom Quote?',
          answers: [
            {
              line: 'If you would like to hire a Vendor but do not see what you are looking for in their list of offers, you can submit a “Custom Quote” request. The Vendor will create a custom quote for you which you will be able to see in your account under “Quote Requests”. You will have 48 hours to accept the offer. If you accept the offer, it will be booked in our system and you will be able to see it in your account under “My Vendors”.'
            },
          ],
          show: false,
          url: null
        },
        {
          question: 'Customer service question?',
          answers: [
            {
              line: 'If you have a question, please contact us at customerservice@mangolola.com'
            },
          ],
          show: false,
          url: null
        },
        {
          question: 'Account management question?',
          answers: [
            {
              line: 'For couples accounts, please contact us at couples@mangolola.com'
            },
            {
              line: 'For vendor accounts, please contact us at vendors@mangolola.com'
            }
          ],
          show: false,
          url: null
        },
        {
          question: 'Can I get a refund on a Booking?',
          answers: [
            {
              line: 'Please read the “Terms and Conditions” policy of the Vendor you Booked.'
            }
          ],
          show: false,
          url: null
        },
        {
          question: 'What happens if a Vendor I book cancels?',
          answers: [
            {
              line: 'In some cases your Vendor might need to cancel your booking. If this occurs, you will need to contact the Vendor and submit a Refund Request Form to them. Mango & Lola will assist you in contacting the Vendor, but the “Terms and Conditions” policy of your booking are between you and the Vendor. If a Vendor needs to cancel your booking, they may try to find a replacement for their services.'
            }
          ],
          show: false,
          url: null
        },
        {
          question: 'What type of payment methods does M&L accept?',
          answers: [
            {
              line: 'We accept Visa, Mastercard, American Express, Discover, JCB, Diners Club, debit cards, and are currently working on accepting other types of payments.'
            }
          ],
          show: false,
          url: null
        },
        {
          question: 'Can I change my Order?',
          answers: [
            {
              line: 'Order changes are subject to the Vendor’s “Terms and Conditions” policy. Mango & Lola provides a “Change Request Form” that must be submitted to the Vendor in order to request an order change. A Change Request Form can be submitted up to 1 week before your Final Payment is due.'
            },
            {
              line: '<br>'
            },
            {
              line: 'Please follow these 3 steps:'
            },
            {
              line: '<br>'
            },
            {
              line: '1. Login to the Mango & Lola website and submit a “Change Request Form” to the Vendor for the order you want to change. You will need to select the item you want changed.'
            },
            {
              line: 'You have the option to add an explanation or description prior to submitting your Change Request Form to the Vendor describing exactly what you want changed.'
            },
            {
              line: '<br>'
            },
            {
              line: '2. When the Vendor receives your Change Request Form in their dashboard, they have the option to accept or reject the changes. If the changes are accepted, the Vendor will adjust the amount due and send a revised invoice back to you for final approval.'
            },
            {
              line: '<br>'
            },
            {
              line: '3. You will receive an email requiring final approval of your original change request, providing an alternate proposal with adjusted price where you have the option to Accept or Reject their proposal, or denying your change request. If the Vendor accepts your original change request or you accept the Vendor’s alternate proposal, Mango & Lola will automatically adjust your final payment.'
            },
            {
              line: '<br>'
            },
            {
              line: 'You can also provide us with your <strong>feedback</strong> by sending an email to: support@mangolola.com '
            },
          ],
          show: false,
          url: null
        },
      ],
      vendorfaqs: [
        {
          question: 'Quick Start Guide for Vendors',
          answers: [
            {
              line: ''
            },
          ],            
          show: false,
          video: null,
          url: "https://spaces.mangolola.com/faq/vendor/M&L%20Vendor%20Quick%20Start%20Guide.pdf"
        },        
        {
          question: 'Is Mango & Lola really free?',
          answers: [
            {
              line: 'Yes, listing your professional business on Mango & Lola is free. There is no monthly fee so you can list, advertise your wedding business, and get discovered by couples nationwide for free.'
            },
          ],            
          show: false,
          url: null,
          video: null,
        },
        {
          question: 'How is Mango & Lola different from other platforms out there?',
          answers: [
            {
              line: 'Mango & Lola offers a new way for you to advertise your company and get booked directly online. We have a simple, yet full-featured platform that is easy to use and built with busy Vendors and Couples like you in mind.'
            },
            {
              line: 'We ask for your available dates, pricing, and area of coverage so we can quickly match you with ideal couples interested in booking your company. Our platform provides real-time information and transparency to make the wedding planning and booking process faster and more efficient.'
            },
            {
              line: 'We pride ourselves on being the first wedding booking platform where couples can actually auto-book your services if you enable that option. You have your own private account with all your bookings in one place so you can easily access them on the go.'
            }
          ],            
          show: false,
          url: null,
          video: null,
        }, 
        {
          question: 'What can Mango & Lola do for my business?',
          answers: [
            {
              line: 'We help you build and grow your online business with the right tools.'
            },
            {
              line: 'We save you money by listing and advertising your business for free so you don’t have to pay a monthly fee like other sites. We only receive a Service Fee when you get paid by Couples.'
            },
            {
              line: 'We save you time by cutting out back-and-forth email messages, ghosting, and guessing games when it comes to booking Couples.'
            },
            {
              line: 'We connect you with your ideal couples when you are available to book them within your price range.'
            },
            {
              line: 'We help you stay up to date with your reservations.'
            },
            {
              line: 'We process invoices so you get paid on time.'
            },
          ],            
          show: false,
          url: null,
          video: null,
        }, 
        {
          question: 'Does Mango & Lola Charge a fee?',
          answers: [
            {
              line: 'A 2.9% Transaction Fee plus 30¢ per transaction is charged by a separate company called Stripe for processing payments.'
            },
          ],            
          show: false,
          url: null,
          video: null,
        }, 
        {
          question: 'How do I know when my account is fully set up and ready to accept bookings?',
          answers: [
            {
              line: 'In your Dashboard there are 6 sections that need to be completed, each with a checkbox that either has a green checkmark in it or is currently blank. Your account is ready to book Couples when all checkboxes have a green checkmark.'
            },
          ],            
          show: false,
          url: null,
          video: null,
        }, 
        {
          question: 'What is Auto-Booking?',
          answers: [
            {
              line: 'With “Auto-Booking”, Couples can book a Vendor instantly without any Vendor approval as long as the wedding is in the Vendor’s “Area of Coverage”. You will be able to see the booking details immediately in your account under “My Bookings”. '
            },
          ],            
          show: false,
          url: null,
          video: null,
        }, 
        {
          question: 'What is a Booking Request?',
          answers: [
            {
              line: 'When you receive a “Booking Request” from a Couple you will have 48 hours to accept it. Once you approve the Couples request, booking details will appear in your account under “My Bookings”.'
            },
          ],            
          show: false,
          url: null,
          video: null,
        }, 
        {
          question: 'How do I know if someone wants to Book me?',
          answers: [
            {
              line: 'You will receive a notification in your Mango & Lola account as well as the email you provided when you set up the account.'
            },
          ],            
          show: false,
          url: null,
          video: null,
        }, 
        {
          question: 'What is a Custom Quote?',
          answers: [
            {
              line: 'If a Couple wants to hire you but does not see what they are looking for in your offers, they can request a “Custom Quote”. When you create a custom quote for them, they will have 48 hours to accept the offer. If they accept the offer, it will be booked and you will be able to see it in your account under “My Bookings”.'
            },
          ],            
          show: false,
          url: null,
          video: null,
        }, 
        {
          question: 'What is “Area of Coverage”?',
          answers: [
            {
              line: 'In your Dashboard under the “Settings” section, please select your area of coverage. From the drop-down list, select the states where you do business. When a couple searches for a Vendor in that area, you will come up.'
            },
          ],            
          show: false,
          url: null,
          video: null,
        }, 
        {
          question: 'Can I request reviews from former customers?',
          answers: [
            {
              line: 'Absolutely, we understand the importance of reviews since Couples primarily use reviews and ratings to decide which vendor to book. Just go to the Reviews section in your Dashboard, click on “Invite for Review” and add the email address of former customers.'
            },
          ],
          show: false,
          url: null,
          video: null,
        },
        {
          question: 'What is the Mango & Lola Badge I’m hearing about?',
          answers: [
            {
              line: 'If you are a registered Vendor on M&L, you can place your Mango & Lola Badge directly on your website and email signature line to book Couples quicker and easier. When a couple clicks on your Badge, it will take them directly to your business on Mango & Lola where they can see your offerings and book you. To get a badge, just send an email to marketing@mangolola.com.'
            },
          ],
          show: false,
          url: null,
          video: null,
        },
        {
          question: 'How do I sign up for my free Mango & Lola account?',
          answers: [
            {
              line: 'You can Register by clicking "Sign Up" to set up your account which takes less than 10 minutes.'
            },
            {
              line: 'If you need any help setting up your M&L account, please sign up for a session with one of our Account Reps by clicking <a href="https://calendly.com/ana-617/group-onboarding-for-mango-lola" target="_blank" >Here</a>.'
            },
          ],
          show: false,
          url: null,
          video: null,
        },
        {
          question: 'I did not receive my Verification Link?',
          answers: [
            {
              line: 'Check your Spam Folder for the Activation Link and if you are on Google, check your "Promotions" Folder located in the top menu.'
            }
          ],
          show: false,
          url: null,
          video: null,
        },
        {
          question: 'I already have a Stripe account so do I need to create a new one?',
          answers: [
            {
              line: 'No, please see info below from Stripe:'
            },
            {
              line: '<div class="tw-block tw-my-4">What is Legal Entity Sharing?  \n' +
                  'Legal Entity Sharing enables Stripe merchants to re-use existing Legal Entities when onboarding new accounts. For example, a merchant may create a Stripe account with Platform A for their legal entity and later decide that they will also onboard with Platform B. This will create a second Stripe account for the same legal entity.</div>'
            },
            {
             line: '<div class="tw-block tw-my-4">How does on-boarding work?</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">* Onboarding is completed using Platform A (the experience will look different depending on the platform).</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">* If this is your first Stripe account, you will be prompted to secure your account using 2FA.</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">* Fill in the account application form. This will include the "Tell us about your business" section, which asks for information about your legal entity. You will also set yourself, or someone at your business who is authorized, as the "Company Representative" (owner of your Stripe account). </div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">* Your first Stripe account is created.</div>'
            },
            {
              line: 'So, if you already have a Stripe account you probably completed the above procedures so to use M&L just follow the next steps: '
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">* If you decide that you would also like to use Platform B, you can use "Legal Entity Sharing".</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">* When you onboard with Platform B, use the *same* email address to create the account.</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">* You will be prompted to link an existing Stripe account or add a new Stripe account.</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">* To share your legal entity, click on "link an existing Stripe account".</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">* Once completed, two Stripe accounts will appear under the toggle</div>'
            },
            {
              line: '<div class="tw-block tw-my-4">We hope this answers your questions. Please let us know if you have any issues setting up. We look forward to having you fully set up on Mango & Lola. \n' +
                  'Here is the link where we found this info: https://support.stripe.com/questions/legal-entity-sharing</div>'
            },
          ],
          show: false,
          url: null,
          video: null,
        },
        {
          question: 'Who should I contact if I have any questions?',
          answers: [
            {
              line: 'To better assist you, please contact us at one of the following addresses: '
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">General Inquiries: info@mangolola.com</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">Legal Questions: legal@mangolola.com</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">Jobs Opportunities: jobs@mangolola.com</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">Marketing & Sales: marketing@mangolola.com</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">Customer Service: support@mangolola.com</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">Press Inquiries: media@mangolola.com</div>'
            },
            {
              line: 'Account Management: '
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">Couples Accounts: couples@mangolola.com</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">Vendor Accounts: vendors@mangolola.com</div>'
            },
            {
              line: '<div class="tw-block tw-my-4 tw-ml-4">Investor Inquiries: investor@mangolola.com</div>'
            }
          ],
          show: false,
          url: null,
          video: null,
        },
        {
          question: 'My Page is not published. What do I need to do?',
          answers: [
            {
              line: '1. Check to make sure you clicked on the “Published” button, and that it turned green.'
            },
            {
              line: '2. Make sure you have green check marks next to each section of your Dashboard. If you do not, this usually means there is missing information that needs to be added.'
            },
            {
              line: '3. If you feel that all your information was added correctly and some checkmarks are still not green, please click on “Refresh”. It might take a few seconds for the checkmark to appear. If you are still having an issue, please email us at vendors@mangolola.com.'
            }
          ],
          show: false,
          url: null,
          video: null,
        },
      ],      
    }
  },
  computed: {
    paused() {
      return !this.playing;
    }
  },
  directives: {
    play: {
      bind(el, binding, vnode) {
        console.log("bind",el);
        el.addEventListener('playing', () => {
          vnode.context[binding.expression] = !el.paused;
        });
        el.addEventListener('pause', () => {
          vnode.context[binding.expression] = !el.paused;
        });
        vnode.context[binding.expression] = !el.paused;
      },
      update(el, binding) {
        console.log("update",el);
        console.log("update binding",binding);
        /*
        if (el.paused) {
          if (binding.value) {
            el.play(el.id);
            console.log("play",binding.value);
          }
        } else if (!binding.value) {
          el.pause(el.id);
          console.log("pause",binding.value);
        }
        */
      }
    }
  },
  methods: {
    updatePaused(event){
      this.videoElements[event.target.id] = event.target.id;
      //console.log("updatePaused");
    },
    play(event) {
      //console.log("play",event.target.id);
      
      if(this.playing && (this.playing != event.target.id)){
        //console.log("playing",this.playing);
        document.getElementById(this.playing).pause();
      }
      this.playing = event.target.id;
    },
    pause(faq,event) {
      //console.log("pause",event);
      //console.log("faq",faq);
      if (this.playing != event.target.id) faq.show = false;
     // this.playing[event.target.id] = false;
    },
  }      
}
</script>
